const API_HOST = require('../config/config').API_HOST;

export const verifyLogin = async ({setUser, setIsLoggedIn}) => {
  let urlParams = new URLSearchParams(window.location.search);
  let token = urlParams.get("token");
  if (!token) token = localStorage.getItem("token");
  if (!token) return false;
  if (!(token.length > 0)) return false;

  try {
    const response = await fetch(`${API_HOST}/account`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.auth) {
      localStorage.setItem("user", data);
      localStorage.setItem("token", token);
      setUser(data);
      setIsLoggedIn(true);
      return data;
    } else {
      localStorage.removeItem("token");
      setIsLoggedIn(false);
      setUser(null);
      return false;
    }
        
  } catch (err) {
    console.log(err);
    setIsLoggedIn(false);
    setUser(null);
    return false;
  }
};


export const verifyToken = ({setUser, setIsLoggedIn}) => {
    let urlParams = new URLSearchParams(window.location.search);
    let token = urlParams.get("token");
    if (!token) token = localStorage.getItem("token");
    if (!token) return false;
    if (!(token.length > 0)) return false;
    
    fetch(`${API_HOST}/account`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.json())
    .then((data) => {
        if (data.auth) {
          localStorage.setItem("user", data);
          localStorage.setItem("token", token);
          setUser(data);
          setIsLoggedIn(true);
          return data;
        } else {
          localStorage.removeItem("token");
          setIsLoggedIn(false);
          setUser(null);
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoggedIn(false);
        setUser(null);
        return false;
      });
};

export const LoginWithEmail = async (email) => {
    const response = await fetch(`${API_HOST}/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email })
    });
    const data = await response.json();
    console.log(data);
    return data;
}

export const SignupWithEmail = async (email) => {
  const response = await fetch(`${API_HOST}/register`, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email })
  });
  const data = await response.json();
  console.log(data);
  return data;
}

export const Logout = async () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  window.location.href = "/";
}