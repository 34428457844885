const API_HOST = require('../config/config').API_HOST;

export const acceptPayment = async (paymentData) => {
    const response = await fetch(`${API_HOST}/api/payment/accept`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(paymentData),
    });
    const data = await response.json();
    return data;
   
}


