import './Billing.css';
import { useEffect } from 'react';
import { acceptPayment } from '../services/APIGateway';


async function handlePayment(data){
    console.log(data);

    const response = await acceptPayment(data);
    window.open(response.iframe, '_blank');
}

export default function Billing() {
    // const [amount, setAmount] = useState(1000);
    const amount = 1000;
    useEffect(() => {
        document.title = "DevCloud - Billing";
    }, []);
    return (
        <div className="billing">
            <h1>Billing</h1>
            <div className='container'>
                <div className='row'>
                    <h2>Payment</h2>
                    <button onClick={() => handlePayment({ amount_cents: amount, currency: 'egp' })}>Pay {amount/100} EGP </button>
                </div>
            </div>


        </div>
    );
}