import './Profile.css';
import { useEffect } from 'react';

export default function Profile() {
    useEffect(() => {
        document.title = "DevCloud - Profile";
    }, []);
    return (
        <div className="profile">
            <h1>Profile</h1>
        </div>
    );
}