import { Routes, Route, Navigate } from 'react-router-dom'; 
import React, { useState, useLayoutEffect } from 'react';

import Login from './routes/Login';
import Header from './components/Header';
import Footer from './components/Footer';

import HomePage from './routes/HomePage';
import ContactUs from './routes/ContactUs';
import AppRoute from './routes/AppRoute';
import Settings from './routes/Settings';
import Profile from './routes/Profile';
import Billing from './routes/Billing';
import NewAppRoute from './routes/NewAppRoute';
import ConsoleRoute from './routes/ConsoleRoute.js';


import { LoginWithEmail, SignupWithEmail, verifyLogin } from './services/AuthService';



export default function App() {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [noHeader, setNoHeader] = useState(false);
  
  useLayoutEffect(() => {
    verifyLogin({setUser, setIsLoggedIn}).then(() => {
      setIsLoading(false);
    });

    let currentPath = window.location.pathname;
    if(currentPath === '/console'){
      setNoHeader(true);
    }
  }, []);

  // todo: move to separate component && make it better
  if (isLoading) return <div>Loading...</div>;
    

  if(!isLoggedIn && !isLoading){
    return (
      <Login LoginWithEmail={LoginWithEmail} SignupWithEmail={SignupWithEmail} />
    );
  }
  console.log(user);

  return (
    <div className="app">
      {/* {noHeader || <Header />} */}
      <Header />
      <Routes>
        <Route path="/login" element={<Navigate to="/" replace />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/dashboard" element={<HomePage />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/apps/new" element={<NewAppRoute />} />
        <Route path="/apps/:id" element={<AppRoute />} />

        <Route path="/console" element={<ConsoleRoute />} />

        <Route path="/settings" element={<Settings />} >
          <Route path="/settings" element={<Profile />} />
          <Route path="/settings/profile" element={<Profile />} />
          <Route path="/settings/billing" element={<Billing />} />
          
        </Route>

      </Routes>
      {noHeader || <Footer />}
      {/* <Footer /> */}
    </div>
  );
}
