import './Login.css';
import React, { useState, useEffect } from 'react';

export default function Login({ LoginWithEmail, SignupWithEmail }) {
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [haveAccount, setHaveAccount] = useState(true);

    useEffect(() => {
        document.title = "DevCloud - Login/Signup";
    }, []);
    
    const onSubmitLogin = (e) => {
        e.preventDefault();
        // todo: validate email
        LoginWithEmail(email);
        setSubmitted(true);
    }
    const onSubmitSignup = (e) => {
        e.preventDefault();
        SignupWithEmail(email);
        setSubmitted(true);
    }
    if (submitted) return <div>Check your email for a login link</div>;
    if (haveAccount) return (
        <div className="login">
            <div className="container">
                <form onSubmit={onSubmitLogin}>
                    <h1>Login</h1>
                    {/* <p> You are not logged in. </p> */}
                    <input type="email" placeholder="email" onChange={(e) => setEmail(e.target.value)} required />
                    <button type='submit' disabled={submitted}>Login</button>
                </form>
                <p onClick={() => setHaveAccount(false)}>Don't have an account? Signup </p>
            </div>

        </div>
    );
    return (
        <div className="login">
            <div className="container">
                <form onSubmit={onSubmitSignup}>
                    <h1>Signup</h1>
                    <input type="email" placeholder="email" onChange={(e) => setEmail(e.target.value)} required />
                    <button type='submit' disabled={submitted}>Signup</button>
                    <p onClick={() => setHaveAccount(true)}>Already have an account? Login </p>
                </form>
            </div>

        </div>
    );

}