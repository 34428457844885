import './AppRoute.css';
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Alert, Stack } from '@mui/material';
import { GetAppDetails, DeleteAppRequest, UpdateApp } from './../services/DockerServer';

export default function AppRoute() {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [isDeleted, setIsDeleted] = useState(false);
    const [updating, setUpdating] = useState(false);
    function handleDeleteSubmit(event) {
        event.preventDefault();
        // const uuid = data.uuid;
        DeleteAppRequest(id).then(response => console.log(response));
        setIsDeleted(true);
    }
    function handleUpdateSubmit(event) {
        event.preventDefault();
        setUpdating(true);
        UpdateApp(id).then(response => console.log(response));
        setUpdating(false);
    }
    useEffect(() => {
        document.title = "DevCloud - App Details";
        GetAppDetails(id).then(response => setData(response));
    }, [id]);
    return (
        <div className="appRoute">
            <h1>App Route</h1>
            {/* <p>  Edit <code>src/routes/AppRoute.js</code> and save to reload. </p> */}
            {/* <p> id: {id} </p> */}
            {/* <div> {data && JSON.stringify(data)} </div> */}
            {isDeleted && <Alert className='deletedAlert' severity="error" onClose={() => { }}> This is no longer available — <Link to='/'>Go back to HomePage</Link></Alert>}
            <div className="appDetails">
                {data && <>
                    {/* <div> _id: <span>{data._id}</span> </div>
                    <div> container_id: <span>{data.container_id}</span> </div>
                    <div> uuid: <span>{data.uuid}</span> </div>
                    <div> container_ip: <span>{data.container_ip}</span> </div>
                    <div> repo_url: <span>{data.repo_url}</span> </div>
                    <div> template: <span>{data.template}</span> </div>
                    <div> project_dir: <span>{data.project_dir}</span> </div>
                    <div> local_url: <span>{data.local_url}</span> </div>
                    <div> domain: <span>{data.domain}</span> </div>
                    <div> url: <span>{data.url}</span> </div>
                    <div> server: <span>{data.server}</span> </div>
                    <div> userId: <span>{data.userId}</span> </div>
                    <div> createdAt: <span>{data.createdAt}</span> </div> */}
                    <table>
                        <thead>
                            <tr> <th>Key</th> <th>Value</th> </tr>
                        </thead>
                        <tbody>
                            {/* {Object.keys(data).map((key, index) => {
                                return ( <tr key={index}> <td>{key}</td> <td>{data[key]}</td> </tr> )
                            })} */}
                            <tr> <td>_id</td> <td>{data._id}</td> </tr>
                            <tr> <td>container_id</td> <td>{data.container_id}</td> </tr>
                            <tr> <td>uuid</td> <td>{data.uuid}</td> </tr>
                            <tr> <td>container_ip</td> <td>{data.container_ip}</td> </tr>
                            <tr> <td>repo_url</td> <td>{data.repo_url}</td> </tr>
                            <tr> <td>template</td> <td>{data.template}</td> </tr>
                            {/* <tr> <td>project_dir</td> <td>{data.project_dir}</td> </tr> */}
                            {/* <tr> <td>local_url</td> <td>{data.local_url}</td> </tr> */}
                            <tr> <td>domain</td> <td>{data.domain}</td> </tr>
                            {/* <tr> <td>url</td> <td>{data.url}</td> </tr> */}
                            <tr> <td>server</td> <td>{data.server}</td> </tr>
                            <tr> <td>userId</td> <td>{data.userId}</td> </tr>
                            <tr> <td>createdAt</td> <td>{data.createdAt}</td> </tr>
                            {/* if there is data.custom_domain show it */}
                            {data.custom_domain && <tr> <td>custom_domain</td> <td>{data.custom_domain}</td> </tr>}
                        </tbody>
                    </table>
                </>}
            </div>
            <div className="updateAppSection">
                <h1>
                    Update Section
                </h1>
                <div className="updateApp">
                <Stack direction="column" spacing={0}>
                    <h3> Update App</h3>
                    <p> Update the app to the latest version from the repo. </p>
                </Stack>
                <button onClick={handleUpdateSubmit} disabled={updating}>Update App</button>
                </div>
            </div>
            <div className="deleteAppSection">
                <h1>
                    Danger Zone
                </h1>
                <div className="deleteApp">
                    <Stack direction="column" spacing={0}>
                        <h3> Delete this App</h3>
                        <p> Once you delete an app, there is no going back. Please be certain.</p>
                    </Stack>
                    <button onClick={handleDeleteSubmit} disabled={isDeleted}>Delete App</button>
                </div>
            </div>
            {/* <div className="appLogs"></div> */}

        </div>
    );
}