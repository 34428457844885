import './AppsList.css';
import { GetAppList } from '../services/DockerServer';
import React, { useState, useEffect } from 'react';
import GitHubIcon from '@mui/icons-material/GitHub';
import { Table, TableBody, TableCell, TableHead, TableRow, Button, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from '@mui/system/styled';

const TableCellStyled = styled(TableCell)({
    color: 'inherit',
    borderBottom: 'none',
});

export default function AppsList() {
    const [data, setData] = useState(null);
    function RepoUrlComponent({repoUrl}) {
        let linkText = repoUrl.toString();
        let i  = linkText.indexOf('github.com/');
        linkText = linkText.substring(i + 'github.com/'.length);
        
        // if eds with .git remove it
        if (linkText.endsWith('.git')) {
            linkText = linkText.substring(0, linkText.length - '.git'.length);
        }
            
        return (
            <Stack direction="row" spacing={1} alignItems="center">
                <GitHubIcon />
                <a className='repoLink no-decoration-link' href={repoUrl}>{linkText}</a>
                {/* <Link className='repoLink' to={repoUrl}>{linkText}</Link> */}
            </Stack>
        );
    }
    useEffect(() => {
        GetAppList().then(response => setData(response));
    }, []);
    return (
        <div className="appsList5">
            <div className='container5'>
                {/* <div className='row'> */}
                <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
                    <h1>Apps List</h1>
                    <Button onClick={() => GetAppList().then(response => setData(response))} variant="outlined">Refresh</Button>
                </Stack>
                <div style={{
                    overflowX: 'auto',
                    width: '100%',
                    maxWidth: '95vw',
                    // display: 'none',
                }}>

                {/* width: 100%;
                border-collapse: collapse;
                border-spacing: 0;
                border: 1px solid #ccc; */}

                <Table style={{
                    // width: '200px',
                    // maxWidth: '100vw',
                    // border: '1px solid #000',
                    // backgroundColor: 'red',
                    overflowX: 'auto',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                    border: '1px solid #ccc',
                    // border: 'none',
                }}>
                    <TableHead>
                        <TableRow style={{
                            // backgroundColor: '#a55', 
                            fontWeight: 'bold', 
                            color: '#fff'
                            
                            }}>
                            {/*                                 
                            <TableCell style={{color: "inherit"}}>No</TableCell>
                            <TableCell style={{color: "inherit"}}>Domain</TableCell>
                            <TableCell style={{color: "inherit"}}>Repo Url</TableCell>
                            <TableCell style={{color: "inherit"}}>Template</TableCell>
                            <TableCell style={{color: "inherit"}}>ID</TableCell> 
                            */}
                            {
                                ["No", "Domain", "Repo Url", "Template", "ID"].map((item, index) => {
                                    return (
                                        <TableCell key={index} style={{
                                            color: "#fff",
                                            fontWeight: '300',
                                            fontSize: '16px',

                                        }}>{item}</TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            data && data.map((item, index) => {
                                return (
                                    <TableRow key={index} 
                                        style={{
                                            // backgroundColor: index % 2 === 0 ? '#2b2d2d' : '#2b2d2d',
                                            color: '#ccc',
                                            border: 'none',
                                            // borderBottom: 'none',
                                        }}
                                        className="hover-row"
                                        >
                                        {/* <TableCell><input type="checkbox" /></TableCell> */}
                                        <TableCellStyled>{index + 1}</TableCellStyled>
                                        <TableCellStyled><a className='domainLink no-decoration-link' href={`http://${item.custom_domain || item.domain}`}>{item.custom_domain || item.domain}</a></TableCellStyled>
                                        <TableCellStyled><RepoUrlComponent repoUrl={item.repo_url || "x"} /></TableCellStyled>
                                        <TableCellStyled>{item.template}</TableCellStyled>
                                        <TableCellStyled><a className='viewAppLink no-decoration-link' href={`/apps/${item._id}`}>{item._id}</a></TableCellStyled>
                                    </TableRow>
                                )
                            })
                        }
                        {
                            !data && (
                                <TableRow>
                                    <TableCell colSpan={5}>Loading...</TableCell>
                                </TableRow>
                            )
                        
                        }
                        {
                            data && data.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={5}>No apps found</TableCell>
                                </TableRow>
                            )
                        
                        }
                    </TableBody>

                </Table>

                </div>



                {/* for testing  */}
                {/* <div> {data && JSON.stringify(data)} </div> */}
            </div>

        </div>
    );
}
