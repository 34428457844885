import './Settings.css';
import { Link, Outlet } from 'react-router-dom';
import { useEffect } from 'react';

export default function Settings() {
    useEffect(() => {
        document.title = "DevCloud - Settings";
    }, []);
    return (
        <div className="settings">
            <h1>Settings</h1>
            <Outlet />
            {/* links */}
            <nav>
                <Link to='/settings/profile'>Profile</Link>
                <Link to='/settings/billing'>Billing</Link>
            </nav>
        </div>
    );
}