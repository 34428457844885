import './NewAppComponent.css';
import React, { useState } from 'react';
import { SubmitDeployRequest } from '../services/DockerServer';

// import DeleteApp from '../components/DeleteApp';
// deploy_request = {
//     'repo_url': 'https://github.com/ahm3dkira/Countdown-to-New-Year.git',
//     'template': 'nginx_html',
// }
export default function NewAppComponent({loading, setLoading}) {
    const [data, setData] = useState([]);
    const [repoUrl, setRepoUrl] = useState('');
    const [templateName, setTemplateName] = useState('');
    function handleSubmit(event) {
        event.preventDefault();
        setLoading(true);
        const deployRequest = {
            repo_url: repoUrl,
            template: templateName
        }
        SubmitDeployRequest(deployRequest).then(response => {
            setLoading(false);
            setData((data)=>[...data, response]);
            console.log(response, data);
        }).catch(error => {
            setLoading(false);
            console.log(error);
        });
    }

    return (
        <div className="newAppComponent">
            <h1> Deploy App </h1>
            {/* <p>  Edit <code>src/routes/HomePage.js</code> and save to reload. </p> */}
            {/* <p>https://github.com/ahm3dkira/Countdown-to-New-Year.git</p> */}
            <form onSubmit={handleSubmit} className="deployForm">
                {/* <label htmlFor="gitUrl">github url</label> */}
                <input type="text" id="gitUrl" name="gitUrl" placeholder='GitHub URL' required onChange={(event) => setRepoUrl(event.target.value)} />
                <select id="template" name="template" required onChange={(event) => setTemplateName(event.target.value)}>
                    <option value="">Select Template</option>
                    {[
                        { value: 'nginx_html', label: 'HTML' },
                        { value: 'nodejs', label: 'Node' },
                        { value: 'django', label: 'Django' },
                        { value: 'php', label: 'PHP' },
                        { value: 'react', label: 'React' },
                        { value: 'angular', label: 'Angular' },
                        { value: 'vuejs', label: 'VueJS' },
                        { value: 'docker-image', label: 'Docker Image' },

                    ].map((option, index) => <option key={index} value={option.value}>{option.label}</option>)
                    
                    }

                </select>

                <button type="submit" disabled={loading}>Submit</button>
            </form>
            <div>
                {/* <h2>logs/response</h2> */}
                <div>
                    {loading && <div>Loading...</div>}
                    {/* <p> {JSON.stringify(data)} </p> */}
                </div>
            </div>
            {/* <DeleteApp /> */}
            {/* <hr /> */}
            {/* <AppsList reload={loading} /> */}

            {/* <p> go to <code>/apps/:id</code> (UUID) to see more details about the deployed app </p> */}

        </div>
    );
}