import './ContactUs.css';
import { useEffect } from 'react';

export default function ContactUs() {
    useEffect(() => {
        document.title = "DevCloud - Contact Us";
    }, []);
    return (
        <div className="contactUs">
            
            <div className="contactUsCover"></div>
            <div className="contactUsContainer">
                <div className="contactUsContentTop">
                <h1>Contact Us</h1>
                <p>
                Welcome to our Contact Us page! We're thrilled that you want to get in touch with us. Here at DevCloud, we're committed to providing the best customer service possible, and we're always here to help.
                </p>
                <br />
                <p>

                Whether you have a question about our products or services, a suggestion for how we can improve, or just want to say hello, we'd love to hear from you. Here are a few ways you can get in touch:
                </p>
                <br />
                </div>
                <ul>
                <li>
                    Email: You can reach us by email at ahmedkira00@gmail.com. We'll do our best to respond within 24 hours.
                </li>
                {/* <li> */}
                    {/* Phone: If you prefer to speak to someone directly, you can give us a call at [insert phone number]. Our hours of operation are [insert hours of operation]. */}
                {/* </li> */}
                <li>               
                    Contact Form: You can also fill out the contact form below, and we'll get back to you as soon as possible.
                </li>
                </ul>
                <br />
                <p>
                    No matter how you choose to reach out, we want you to know that we value your feedback and appreciate your interest in our company. Thank you for considering DevCloud for your needs, and we look forward to hearing from you soon!
                </p>
                {/* <p>  Edit <code>src/routes/ContactUs.js</code> and save to reload. </p> */}
            </div>

        </div>
    );
}