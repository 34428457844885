import './NewAppRoute.css';
import React, { useState, useEffect } from 'react';
import { SubmitDeployRequest } from './../services/DockerServer';

export default function NewAppRoute() {
    const [data, setData] = useState(null);
    const [repoUrl, setRepoUrl] = useState('');
    const [templateName, setTemplateName] = useState('');
    const [env, setEnv] = useState([]);
    const [currentEnv, setCurrentEnv] = useState('');

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = "DevCloud - Deploy New App";
    }, []);
    
    function handleSubmit(event) {
        event.preventDefault();
        setLoading(true);
        const deployRequest = {
            repo_url: repoUrl,
            template: templateName,
            custom_env: env
        }
        SubmitDeployRequest(deployRequest).then(response => {
            setLoading(false);
            setData(response);
            console.log(data);
        }).catch(error => {
            setLoading(false);
            console.log(error);
        });
    }
    return (
        <div className="newAppRoute">
            <h1>NewAppRoute</h1>
            {/* <p>  Edit <code>src/routes/HomePage.js</code> and save to reload. </p> */}
            {/* <p>https://github.com/ahm3dkira/Countdown-to-New-Year.git</p> */}
            <form onSubmit={handleSubmit} className="deployForm">
                {/* <label htmlFor="gitUrl">github url</label> */}
                <div className="requiredFields">
                    <h3> Deploy App </h3>
                    <input type="text" id="gitUrl" name="gitUrl" placeholder='GitHub URL' required onChange={(event) => setRepoUrl((event.target.value).trim())} />
                    <select id="template" name="template" required onChange={(event) => setTemplateName(event.target.value)}>
                        <option value="">Select Template</option>
                        <option value="nginx_html">HTML</option>
                        <option value="nodejs">Node</option>
                        <option value="django">Django</option>
                        <option value="php">PHP</option>
                        <option value="react">React</option>
                        <option value="angular">Angular</option>
                        <option value="vuejs">VueJS</option>
                        <option value="docker-image">Docker Image</option>

                    </select>
                </div>
                {/* add input for multiple strings */}
                <div className="envFields">
                    <h3 htmlFor="env">Environment Variables</h3>
                    {
                        env.map((item, index) => {
                            return (
                                <div key={index}>
                                    <input type="text" id="env" name="env" disabled value={item} />
                                    <button type="button" onClick={(event) => { setEnv(env.filter((_, i) => i !== index)) }}>Remove</button>
                                </div>
                            )
                        })
                    }
                    <input type="text" id="env" name="env" placeholder='key=value' onChange={(event) => setCurrentEnv((event.target.value).trim())} 
                        value={currentEnv} />
                    <button type="button" onClick={(event) => {
                        if (!currentEnv) return; 
                        const isValid = currentEnv.match(/^[a-zA-Z0-9_]+=.*$/);
                        if (!isValid) return;
                        setEnv([...env, currentEnv]); 
                        setCurrentEnv('');
                        console.log(env)
                        console.log(JSON.stringify(env))
                    }}>Add</button>
                </div>
                {/* {
                    env && env.length 
                } */}


                <button type="submit" disabled={loading}>Submit</button>
            </form>
            <div> {loading && <div>Loading...</div>} </div>
            {/* <hr /> */}
            {/* <hr /> */}
            {/* <p> go to <code>/apps/:id</code> (UUID) to see more details about the deployed app </p> */}

        </div>
    );
}