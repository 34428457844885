import './ConsoleRoute.css';
import React, { useEffect } from 'react';
import Terminal from 'react-console-emulator'
import { GetAppList, UpdateApp, GetAppDetails, DeleteAppRequest, SubmitDeployRequest, SetCustomDomain, UpdateConfIp } from '../services/DockerServer';
import { Logout } from '../services/AuthService';
import WhoAmI from '../utils/WhoAmI';
import isValidObjectId from '../utils/isValidObjectId';

export default function HomePage() {
    // const [loading, setLoading] = useState(false);
    useEffect(() => {
        document.title = "DevCloud - Console";
    }, []);

    const commands = {
        echo: {
            description: 'Echo a passed string.',
            usage: 'echo <string>',
            fn: function () {
                return `${Array.from(arguments).join(' ')}`
            }
        },
        whoami: {
            description: 'Get current user details',
            usage: 'whoami',
            fn: function () {
                const data = WhoAmI();
                console.log(data);
                if (arguments[0] === '--id') {
                    return `${data._id}`;
                }
                
                return `${data.email}`;
            }
        },
        logout: {
            description: 'Logout current user',
            usage: 'logout',
            fn: function () {
                Logout();
                return `You have been logged out`;
            }
        },
        // create function to list all apps with command "apps ls" and apps details with command "apps details <id>"
        apps: {
            description: 'apps control',
            // add tabes before each line
            usage:  `apps ls (list all apps)
                    | apps details <id> (get details of app with id) 
                    | apps delete <id> (delete app with id) 
                    | apps update <id> (update app with id) 
                    | apps deploy <repo_url> <template_name { html | nodejs | react }> (deploy new app)`,
            fn: async function () {
                const lowerCaseArg1 = arguments[0].toLowerCase();

                if (lowerCaseArg1 === 'ls') {
                    const data = await GetAppList();
                    console.log(data);
                    if (arguments[1] === '-c' || arguments[1] === '--count') {
                        return `Total apps: ${data.length}`;
                    }
                    if (data.length === 0) {
                        return `No apps found`;
                    }
                    return data.map((item, index) => {
                        return `${item._id} `;
                    });
                } else if (lowerCaseArg1 === 'details') {
                    const id = arguments[1].toLowerCase();
                    if (!isValidObjectId(id)) {
                        return `Invalid ID`;
                    }
                    const data = await GetAppDetails(id);
                    console.log(data);
                    return `ID: ${data._id}
                    Domain: ${data.domain}
                    Repo Url: ${data.repo_url}
                    Template: ${data.template}
                    Status: ${data.status}
                    Created At: ${data.createdAt}
                    `;
                } else if (lowerCaseArg1 === 'delete') {
                    // get argument length if it is less than 2 return invalid command
                    if (arguments.length < 2) {
                        return `Invalid command. Please use "apps help" to see available commands`;
                    }
                    let response = '';
                    // apps delete <id> <id> <id> .......
                    // loop through all arguments and delete all apps with id
                    for (let i = 1; i < arguments.length; i++) {
                        const id = arguments[i].toLowerCase();
                        if (!isValidObjectId(id)) {
                            response += `Invalid ID: ${id}\n`;
                            continue;
                        }
                        const data = await DeleteAppRequest(id);
                        console.log(data);
                        response += `App with ID: ${id} has been deleted\n`;
                    }

                    return response;
                } else if (lowerCaseArg1 === 'update') {
                    const id = arguments[1].toLowerCase();
                    if (!isValidObjectId(id)) {
                        return `Invalid ID`;
                    }
                    const data = await UpdateApp(id);
                    console.log(data);
                    return `App with ID: ${id} has been updated`
                } else if (lowerCaseArg1 === 'deploy') {
                    if (arguments[1].toLowerCase() === 'help' || arguments[1].toLowerCase() === '?' || arguments[1].toLowerCase() === 'h') {
                        return `apps deploy <repo_url> <template_name { html | nodejs | react }>`;
                    }
                    if (arguments.length < 3) {
                        return `Invalid command. Please use "apps help" to see available commands`;
                    }
                    const repoUrl = arguments[1].toLowerCase();
                    let templateName = arguments[2].toLowerCase();
                    if (templateName === 'html') templateName = 'nginx_html'; 
                    if (templateName !== 'nginx_html' && templateName !== 'nodejs' && templateName !== 'react') {
                        return `Invalid template name. Please use "apps deploy help" to see available templates`;
                    }
                    const deployRequest = {
                        repo_url: repoUrl,
                        template: templateName,
                        custom_env: []
                    }
                    const data = await SubmitDeployRequest(deployRequest);
                    console.log(data);
                    return `App with ID: ${data.appId} has been deployed
                    you can view it live at http://${data.data.domain}`;

                } else if (lowerCaseArg1 === 'set-domain') {
                    if (arguments.length < 3) {
                        return `Invalid command. Please use "apps help" to see available commands`;
                    }
                    const id = arguments[1].toLowerCase();
                    if (!isValidObjectId(id)) {
                        return `Invalid ID`;
                    }
                    const domain = arguments[2].toLowerCase();
                    const data = await SetCustomDomain(id, domain);
                    console.log(data);
                    return `Domain has been set to ${domain} for app with ID: ${id}`;
                } else if (lowerCaseArg1 === 'update-ip') {
                    if (arguments.length < 2) {
                        return `Invalid command. Please use "apps help" to see available commands`;
                    }
                    const id = arguments[1].toLowerCase();
                    if (!isValidObjectId(id)) {
                        return `Invalid ID`;
                    }
                    const data = await UpdateConfIp(id);
                    console.log(data);
                    return `App with ID: ${id} has been updated`
                } else if (lowerCaseArg1 === 'help' || lowerCaseArg1 === '?' || lowerCaseArg1 === 'h') {
                    return `apps ls or apps details <id> or apps delete <id>`;
                } else {
                    return `Invalid command. Please use "apps help" to see available commands`;
                }

            }
        },
        ping: {
            description: 'PingPong',
            usage: 'ping',
            fn: function () {
                return 'pong'
            }
        },
        exit: {
            description: 'Exit the console',
            usage: 'exit',
            fn: function () {
                // navigate to home page
                window.location.href = '/';
                return 'Bye!'
            }
        },

    }

    return (
        <div className="consoleRoute">
            <Terminal
                commands={commands}
                welcomeMessage={'Welcome to DevCloud Console!'}
                promptLabel={'devcloud@console:~$'}
                // noEchoBack={true}
                autofocus={true}

                style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "black",
                    // color: "white", rgb(240, 191, 129)
                    // color: "rgb(240, 191, 129)",
                    fontSize: "1.2rem",
                    fontFamily: "monospace",
                    fontWeight: "bold",
                    overflow: "auto"
                }}
            />
            
     
        </div>
    );
}