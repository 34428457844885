const API_HOST = require('../config/config').API_HOST;


export const SubmitDeployRequest = async (deployRequest) => {
    const response = await fetch(`${API_HOST}/api/docker/containers/create`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(deployRequest)
    });
    const data = await response.json();
    return data;
}
// export const GetDeployRequest = async (deployRequestId) => {...}
export const DeleteAppRequest = async (id) => {
    if (!id.match(/^[0-9a-fA-F]{24}$/)) {
        return null;
    }
    const response = await fetch(`${API_HOST}/api/docker/containers/delete/${id}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({
            app_uuid: id,
            app_id: id
        })
    });
    const data = await response.json();
    return data;
}

export const GetAppList = async () => {
    const response = await fetch(`${API_HOST}/api/docker/containers/list`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
    });
    const data = await response.json();
    return data;
}
export const GetAppDetails = async (id) => {
    // check if id is a valid ObjectId before sending request
    if (!id.match(/^[0-9a-fA-F]{24}$/)) {
        return null;
    }
    const response = await fetch(`${API_HOST}/api/docker/containers/apps/${id}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        });
    const data = await response.json();
    return data;
}
export const UpdateApp = async (id) => {
    if (!id.match(/^[0-9a-fA-F]{24}$/)) {
        return null;
    }
    const response = await fetch(`${API_HOST}/api/docker/containers/update/${id}`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify({
                "msg": "update"
            })
        });
    const data = await response.json();
    return data;
}
export const SetCustomDomain = async (id, domain) => {
    const response = await fetch(`${API_HOST}/api/config/custom-domain`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
            custom_domain_name: domain,
            app_id: id,
        })
    });
    const data = await response.json();
    return data;
}

export const UpdateConfIp = async (id) => {
    const response = await fetch(`${API_HOST}/api/config/update-ip`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
            app_id: id,
        })
    });
    const data = await response.json();
    return data;
}
