import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

ReactGA.initialize('G-1SH4L50CMY');

// var faro = initializeFaro({
//   url: 'https://faro-collector-prod-us-east-0.grafana.net/collect/1ceb94abbe83721361c27ff49f287ee6',
//   app: {
//     name: 'devcloud-react',
//     version: '1.0.0',
//     environment: 'production'
//   },
//   instrumentations: [
//     // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
//     ...getWebInstrumentations(),

//     // Initialization of the tracing package.
//     // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
//     new TracingInstrumentation(),
//   ],
// });
// faro.api.pushLog(['INDEX_JS', 'faro initialized']);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
