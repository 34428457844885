import './HomePage.css';
import React, { useState, useEffect } from 'react';

import { Box, Stack } from '@mui/material';
import AppsList from '../components/AppsList';
import NewAppComponent from '../components/NewAppComponent';


export default function HomePage() {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        document.title = "DevCloud - Home";
    }, []);

    return (
        <div className="homePage">
            <Stack direction="column" justifyContent={'space-between'} alignItems={'center'} style={{ width: '100vw' }} padding={2} spacing={4}>
                <NewAppComponent loading={loading} setLoading={setLoading} />
                {  loading || <AppsList /> }
            </Stack>

            <Box sx={{ mb: 1 }} />
            {/* <p> go to <code>/apps/:id</code> (UUID) to see more details about the deployed app </p> */}

        </div>
    );
}